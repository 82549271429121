import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import Pagination from "react-js-pagination";
import { IoCopy } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import slugify from 'react-slugify';

import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
// import Table from "./Table";
import ModalActivate from "../../components/Modal/ActivateProject/ActivateProject";
import Context from "../../pages/Context";

import ProjectApi from "../../api/ProjectApi";

import classes from "./Projects.module.css";

class Projects extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			table: [
				{
					id: 1,
					name: "Project 1",
					number: "1234",
					status: "Expired",
					action: "Activate",
				},
				{
					id: 2,
					name: "Project 2",
					number: "5678",
					status: "Not Activated",
					action: "Activate",
				},
				{
					id: 3,
					name: "Project 3",
					number: "9101",
					status: "Active Til 090922",
					action: "",
				},
				{
					id: 4,
					name: "Project 4",
					number: "1213",
					status: "Expired",
					action: "Activate",
				},
			],
			Projects: [],
			isData: false,

			isActivate: false,
			activateProject: false,
			isRequestActivate: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			activeOrganization: false,
			boardPreviewToken: false,
			transPage: "project_list.",
			transPage1: "activate_project_form.",
			transPageT: "table."
		};
	}

	componentDidMount() {
		this.handleProject();
	}

	componentDidUpdate() {
		const { user, Organization, activeOrganization } = this.context;

		if (user && Organization && Organization.length > 0) {
			if (this.state.activeOrganization !== activeOrganization) {
				this.setState(
					{
						activeOrganization: activeOrganization,
					},
					() => {
						const us = JSON.parse(user);

						let param = this.getProjectByPrivilege(us);
						this.getProjects(param);
					}
				);
			}
		} else if (user && Organization && Organization.length === 0) {
			if (this.state.activeOrganization !== activeOrganization) {
				this.setState({
					activeOrganization: activeOrganization,
					Projects: [],
				});
			}
		}
	}

	handleProject = () => {
		const { user, Organization } = this.context;

		if (user) {
			const us = JSON.parse(user);

			if (Organization && Organization.length > 0) {
				let param = this.getProjectByPrivilege(us);
				this.getProjects(param);
			} else {
				this.setState({
					isData: true,
					Projects: [],
				});
			}
		}
	};

	getProjectByPrivilege = (us) => {
		const { activeOrganization } = this.context;
		const { activePage } = this.state;

		const groups = us.groups ? us.groups[0] : false;

		let param;
		if (us.is_superuser) {
			if (activeOrganization) {
				param = {
					organization: activeOrganization ? activeOrganization : "",
					page: activePage ? activePage : 1,
				};
			} else {
				param = {
					page: activePage ? activePage : 1,
				};
			}
		} else {
			if (groups && ([7, 9].includes(groups.id) || ["Owner", "Organization Manager"].includes(groups.name))) {
				param = {
					client: us.client,
					organization: activeOrganization ? activeOrganization : "",
					page: activePage ? activePage : 1,
				};
			} else if (groups && (groups.id === 2 || groups.name === "Manager")) {
				param = {
					client: us.client,
					organization: activeOrganization,
					managers: us.id ? us.id : "",
					page: activePage ? activePage : 1,
				};
			}
		}
		return param;
	};

	getProjects(params) {
		const { user } = this.context;
		const PA = new ProjectApi();

		if (user) {
			PA.getProjects(params)
				.then((res) => {
					this.setState({ Projects: res.data.results, isData: true, dataCount: res.data.count }, () => {
						this.setPagination();
					});
				})
				.catch((err) => console.log(err));
		}
	}

	setPagination = () => {
		const { Projects } = this.state;
		let totalData = Projects.length;
		let currentPageData;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageData = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageData = totalData;
			}
		} else {
			// currentPageData = totalData - (this.state.activePage - 1) * this.state.limitPageData;
			currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageData,
			totalCount: totalData,
		});
	};

	copyProjects(id) {
		const PA = new ProjectApi();

		if (id) {
			this.setState(
				{
					isData: false,
				},
				() => {
					PA.copyProject({ id: id })
						.then((res) => {
							if (res && res.status === 201 && res.data?.project) {
								toast.success("Copy project Success, Redirecting . . .", {
									duration: 1000,
									className: "toast-notification",
								});
								setTimeout(() => {
									this.props.history.push(`project/${res.data?.project}`)
								}, 1000);
							}
						})
						.catch((err) => {
							if (err) {
								toast.error("Copy project Failed, please try again", {
									duration: 5000,
									className: "toast-notification",
								});
								setTimeout(() => {
									this.props.history.replace(`projects`)
								}, 5000);
							}
						});
				}
			);
		}
	}

	activateProject = (project) => {
		this.setState({
			isActivate: !this.state.isActivate,
			activateProject: !this.state.activateProject ? project : false,
		});
	};

	requestActivate = (data) => {
		const PA = new ProjectApi();

		PA.activateProject(data)
			.then((res) => {
				if (res) {
					toast.success("Activate Request Sent", {
						duration: 1000,
						className: "toast-notification",
					});
					this.setState({
						isActivate: !this.state.isActivate,
						isRequestActivate: !this.state.isRequestActivate,
					});
				}
			})
			.catch((err) => console.log(err));
	};

	resetAll = () => {
		this.setState(
			{
				isActivate: false,
				isRequestActivate: false,
			},
			() => {
				this.handleProject();
			}
		);
	};

	addNewProject = () => {
		localStorage.removeItem("project");
		this.props.history.push("/project/new")
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.handleProject();
			}
		);
	};

	getTokenBoardProject = (projectId, param, project) => {
		if (projectId) {
			const PA = new ProjectApi();

			PA.getTokenBoardProject(projectId, param)
				.then((res) => {
					this.setState({
						boardPreviewToken: res.data.key,
					});
					if (res.data.key) {
						setTimeout(() => {
							if (param === "board") {
								// window.open(`http://app.digibim.info/login/?auth=true&board=${projectId}&token=${res.data.key}`);
								window.open(`${process.env.REACT_APP_WEB_URL}login/?auth=true&preview=true&organization=${slugify(project.organization)}&board_id=${projectId}&board_number=${slugify(project.number)}&board_name=${slugify(project.name)}&token=${res.data.key}`);
							} else {
								// window.open(`http://app.digibim.info/login/?auth=true&preview=${projectId}&token=${res.data.key}`);
								window.open(`${process.env.REACT_APP_WEB_URL}login/?auth=true&preview=false&organization=${slugify(project.organization)}&board_id=${projectId}&board_number=${slugify(project.number)}&board_name=${slugify(project.name)}&token=${res.data.key}`);
							}
							this.setState({
								boardPreviewToken: false,
							});
						}, 500);
					}
				})
				.catch((err) => {
					console.log("error", err);
				});
		}
	};

	previewBoard = (param, status, project) => {
		if (status) {
			if (status.includes("Active until") && status !== "Not Activated") {
				this.getTokenBoardProject(param, "board", project);
			} else {
				this.getTokenBoardProject(param, "preview", project);
			}
		}
	};
	render() {
		const { isData, Projects } = this.state;
		const { user, activeOrganization, isSuperUser } = this.context;

		const { t } = this.props;
		const { transPage, transPage1, transPageT } = this.state;

		let html;
		let columnsTable;
		let canActivate;
		if (user) {
			const us = JSON.parse(user);
			const groups = us.groups ? us.groups[0] : false;

			// if (groups && ((groups.id === 7 || groups.name === "Owner") || (groups.id === 2 || groups.name === "Manager"))) {
			if (isSuperUser || (groups && ([7, 2].includes(groups.id) || ["Owner", "Manager"].includes(groups.name)))) {
				canActivate = true;
			}

			columnsTable = [
				{
					name: t(transPage + "project_name"),
					selector: (row) => row.name,
					sortable: true,
					cell: (row) => {
						return (
							<Link to={`/project/${row.id}`} >
								<span style={{ cursor: "pointer" }}>
									{row.name}
								</span>
							</Link>

						);
					},
				},
				{
					name: t(transPage + "project_number"),
					selector: (row) => row.number,
					sortable: true,
				},
				{
					name: t(transPage + "status"),
					selector: (row) => row.status,
					sortable: true,
					cell: (row) => {
						let styles;
						switch (row.status) {
							case "Expired":
								styles = classes.statusExpired;
								break;
							case "Not Activated":
								styles = classes.statusNotActivated;
								break;
							default:
								styles = classes.statusDefault;
								break;
						}
						return <div className={styles}>{row.status}</div>;
					},
				},
				{
					name: "",
					selector: (row) => row.status,
					sortable: true,
					cell: (row) => {
						let html;
						if (row) {
							const stat = row.status;

							if (stat === "Not Activated" || stat === "Cancelled" || stat === "Expired") {
								html = (
									<button className={["btn-bim btn-bim-white small", !canActivate ? "bim-not-allowed" : ""].join(" ")} onClick={canActivate ? () => this.activateProject(row) : undefined}>
										{t("activateU")}
									</button>
								);
							} else if (stat.includes("Active until")) {
								html = <span>-</span>;
							} else if (stat === "Request Sent") {
								html = <span>Waiting</span>;
							}
						} else {
							html = <></>;
						}
						return html;
					},
				},
				{
					name: "",
					selector: (row) => row.id,
					cell: (row) => {
						const p = {
							id: row.id,
							name: row.name,
							number: row.number,
							organization: row.organization?.name || "-"
						}
						return (
							<div>
								<FaEye onClick={() => this.previewBoard(row.id, row.status, p)} className="me-3" />
								<>
									<IoCopy onClick={() => this.copyProjects(row.id)} />
								</>
							</div>
						);
					},
				},
			];

			if (!isData) {
				html = (
					<div className="section-loader">
						<Loader loading={!isData} />
					</div>
				);
			} else {
				if (Projects) {
					html = (
						<>
							<Button icon="" style={classes.btnProjects} onClick={this.addNewProject} isDisable={activeOrganization ? false : true} />


							{/* <Table data={this.state.Projects} activateProject={this.activateProject} /> */}
							<>
								<div id="tblProject">
									<DataTable
										columns={columnsTable}
										data={this.state.Projects}
										className={["table table-bordered dataTable", classes.table].join(" ")}
										role="grid"
										// defaultSortField="name"
										defaultSortFieldId={1}
										defaultSortAsc={false}
										selectableRowsHighlight={true}
										highlightOnHover={true}
										noDataComponent={t(transPage + "no_projects")}
									/>
								</div>

								<div className={classes.pagination}>
									<Pagination
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.limitPageData}
										totalItemsCount={this.state.dataCount}
										pageRangeDisplayed={5}
										onChange={this.handlePageChange.bind(this)}
										itemClass="page-item"
										linkClass="page-link"
										firstPageText={t(transPageT + "first")}
										prevPageText={t(transPageT + "prev")}
										nextPageText={t(transPageT + "next")}
										lastPageText={t(transPageT + "last")}
									/>
									<div className="d-flex ml-3">
										<span className="mt-auto mr-0 mb-auto ml-auto" style={{ font: "12px Roboto", color: "#707070" }}>
											{t(transPageT + "showing")} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + "of")} {this.state.currentPageData} {t(transPageT + "from")} {this.state.dataCount}
										</span>
									</div>
								</div>
							</>
						</>
					);
				} else {
					html = (
						<div className={classes.emptyProjects}>
							{t(transPage + "no_projects")}
							<Link to="/project/new">
								<Button icon="" style={classes.btnProjects} />
							</Link>
						</div>
					);
				}
			}
		}

		return (
			<div className="main-section">
				<div className="content-section">
					{this.state.isRequestActivate ? (
						<div className={classes.isRequestActivate}>
							{t(transPage1 + "project_message_activation")}
							<button className="btn-bim small bim-pd-btn" onClick={this.resetAll}>
								{t("ok")}
							</button>
						</div>
					) : (
						html
					)}
				</div>
				<ModalActivate
					show={this.state.isActivate}
					onHide={() => {
						this.setState({
							isActivate: !this.state.isActivate,
							activateProject: false,
						});
					}}
					project={this.state.activateProject}
					action={this.requestActivate}
				/>
			</div>
		);
	}
}
export default withRouter(withTranslation()(Projects));
